import * as React from 'react';
import Helmet from 'react-helmet';
import { withLayout, LayoutProps } from '../../components/Layout';
import { FixedMasthead } from '../../components/Masthead/FixedMasthead.component';
import styled from '../../styled-components';
import { graphql } from 'gatsby';
import {
    Constrain,
    pullBackSmall,
    pullbackMarginToNormalConstrainFromNarrow,
    pullbackMarginsWide,
    HeadingLevel3
} from '../../components/ui';
import CallToContact from '../../components/CallToContact/CallToContact.component';
import { media } from '../../utilities';
import theWaveMachineImage from '../../images/the-wave-machine.svg';
import theWaveMachineSocialImage from '../../images/the-wave-machine-social.png';
import IconClock from '../../images/icons/clock.svg';
import IconMarker from '../../images/icons/marker.svg';
import meetingRoom from '../../images/meeting-room.jpg';
import { BookACall } from '../../components/BookACall/BookACall';
import { QuerySiteArgs } from '../../generated/graphql-types';
import IconTick from '../../images/icons/tick.svg';
import { SwellWrapper } from '../../components/SwellWrapper/SwellWrapper.component';

const DigitalProductClinicPageMastHead = styled.div`
    margin-top: ${({ theme }) => theme.spacing.l};
    margin-bottom: ${({ theme }) => theme.spacing.l};

    h1 {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.secondary.size.level4.lineHeight};
        color: ${({ theme }) => theme.colors.pink.color};
        font-weight: normal;
        margin-bottom: 1.5rem;

        ${media.xl`
            font-size: ${({ theme }) =>
                theme.fontNew.secondary.size.level2.size};
            line-height: ${({ theme }) =>
                theme.fontNew.secondary.size.level2.lineHeight};
        `}
    }

    h2 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level5.lineHeight};
        em {
            font-style: normal;
            border-bottom: 2px solid ${({ theme }) => theme.colors.pink.color};
            white-space: nowrap;
        }

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    img {
        display: block;
        margin: 0 ${pullBackSmall};
        width: calc(100% + 6rem);
        ${media.l`
            ${pullbackMarginsWide}
            width: calc(100% + 12rem);
        `}

        ${media.xl`
            ${pullbackMarginToNormalConstrainFromNarrow}
            width: calc(100% + 19.6rem);
        `}
    }
`;

const DigitalProductClinicPageContent = styled.div`
    .intro {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level7.lineHeight};
        font-weight: 500;

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level5.lineHeight};
        `}
    }

    .spacer {
        margin-bottom: ${({ theme }) => theme.spacing.l};

        ${media.xl`
            margin-bottom: ${({ theme }) => theme.spacing.xl};
        `}
    }

    h2 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level4.lineHeight};
        margin-top: ${({ theme }) => theme.spacing.l};
        margin-bottom: ${({ theme }) => theme.spacing.m};

        ${media.l`
            margin-top: ${({ theme }) => theme.spacing.xl};
        `}

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    h3 {
        margin-top: ${({ theme }) => theme.spacing.l};

        ${media.xl`
            margin-top: ${({ theme }) => theme.spacing.xl};
        `}
    }

    h4 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level7.lineHeight};
        margin-top: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level5.lineHeight};
        `}
    }

    ul {
        margin-bottom: ${({ theme }) => theme.spacing.m};
        padding-left: 2rem;
        position: relative;

        & > li {
            list-style: none;
            margin-bottom: 1rem;
            position: relative;

            &:before {
                content: '';
                height: 0.9rem;
                width: 0.9rem;
                display: block;
                left: -2rem;
                top: 1.2rem;
                z-index: 10;
                position: absolute;
                background-color: ${({ theme }) => theme.colors.green.color};
            }
        }
    }

    img {
        display: block;
    }

    .this-clinic-is-for-you {
        ${media.l`
            ${pullbackMarginsWide}
        `}

        ${media.xl`
            ${pullbackMarginToNormalConstrainFromNarrow}
        `}
        ul {
            ${media.m`
                columns: 2;
                column-gap: 6rem;
            `}
        }
    }

    .what-you-can-expect {
        ${media.m`
            display: flex;
            margin: 0 -2rem;
            & > * {
                flex: 1;
                padding: 0 2rem;

            }
        `}
    }

    .additional-questions {
        margin-bottom: ${({ theme }) => theme.spacing.l};

        ${media.l`
            margin-bottom: ${({ theme }) => theme.spacing.xl};
        `}
    }
`;

const IconList = styled.ul`
    padding: 0 !important;
    margin: 0 !important;

    li {
        margin: 0;
        margin-bottom: 2rem !important;
        display: flex;
        align-items: center;
        padding: 0;

        &:before {
            display: none !important;
        }
    }

    svg {
        margin-right: 1rem;
        width: 45px;

        & #tick {
            fill: ${({ theme }) => theme.colors.orange.color};
        }
    }
`;

interface IDigitalProductClinicPageProps extends LayoutProps {
    data: {
        site: QuerySiteArgs;
    };
}

const DigitalProductClinic = ({
    data,
    location
}: IDigitalProductClinicPageProps) => {
    return (
        <>
            <Helmet>
                <title>The Wave Machine - a free digital product clinic</title>
                <meta
                    name="description"
                    content="In two hours, our free product clinic will give you a clear understanding of the steps you need to take in order to make a success of your digital product."
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />

                <meta
                    name="twitter:title"
                    content="The Wave Machine - a free digital product clinic"
                />
                <meta
                    name="twitter:description"
                    content="A fun and informal session where we’ll explore your
                    goals and uncover the barriers that are getting in
                    your way."
                />

                <meta
                    name="twitter:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${theWaveMachineSocialImage}`}
                />

                <meta
                    property="og:title"
                    content="The Wave Machine - a free digital product clinic"
                />

                <meta
                    property="og:description"
                    content="A fun and informal session where we’ll explore your
                    goals and uncover the barriers that are getting in
                    your way."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }/the-wave-machine-digital-product-clinic`}
                />
                <meta
                    property="og:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${theWaveMachineSocialImage}`}
                />
            </Helmet>
            <FixedMasthead />

            <DigitalProductClinicPageMastHead>
                <Constrain type="narrow">
                    <h1>The Wave Machine</h1>
                    <h2>
                        From fine tuning to major overhaul, our{' '}
                        <em>free product clinic</em> is here to help you turn
                        ripples into waves.
                    </h2>
                    <img
                        src={theWaveMachineImage}
                        alt="Illustration of a surfer on a surf board wondering where the waves are"
                    />
                </Constrain>
            </DigitalProductClinicPageMastHead>
            <DigitalProductClinicPageContent>
                <Constrain type="narrow">
                    <p className="intro spacer">
                        Around 50% of all IT projects fail, 9 out of 10 startups
                        bite the dust and a whopping 95% of ‘Digital
                        Transformation’ initiatives are doomed. The good news is
                        there are ways to increase your chances of success and
                        during this clinic we’ll be sharing them with you.
                    </p>
                    <div className="this-clinic-is-for-you">
                        <HeadingLevel3>
                            This clinic is for you if:
                        </HeadingLevel3>

                        <ul>
                            <li>
                                You’re wondering how much your product idea will
                                cost and how long it will take
                            </li>
                            <li>
                                You don’t know if your idea is technically
                                feasible
                            </li>
                            <li>
                                You’re not getting any feedback from your users
                            </li>
                            <li>
                                You’re spending too much money and missing
                                deadlines
                            </li>
                            <li>You have buggy software</li>
                            <li>
                                You’ve built a product but it’s hard to adapt
                            </li>
                            <li>
                                You’re experiencing frustration with your
                                development process
                            </li>
                            <li>
                                You have an idea but don’t know where to start
                            </li>
                            <li>
                                You‘ve built some software but aren't sure what
                                to do next
                            </li>
                        </ul>
                    </div>

                    <h2>What you can expect</h2>

                    <div className="what-you-can-expect">
                        <div>
                            <p className="intro">
                                A fun and informal session where we’ll explore
                                your goals and uncover the barriers that are
                                getting in your way.
                            </p>

                            <IconList>
                                <li>
                                    <IconClock />
                                    No more than 2 Hours
                                </li>
                                <li>
                                    <IconMarker />
                                    WeWork, 41 Corsham Street
                                    <br />
                                    London, N1 6DR
                                </li>
                            </IconList>
                        </div>
                        <div>
                            <img
                                src={meetingRoom}
                                alt="Photo of informal room where we run the clinic"
                            />
                        </div>
                    </div>

                    <h2>What you'll walk away with</h2>

                    <IconList>
                        <li>
                            <IconTick />A clearer idea of the steps you need to
                            take in order to make a success of your product
                        </li>
                        <li>
                            <IconTick />A short report highlighting our
                            recommendations
                        </li>
                    </IconList>
                </Constrain>
                
                <SwellWrapper title="Surf's up." subtitle="Book an appointment">    
                    <BookACall
                        calendlyUrl="https://calendly.com/we-make-waves/book?background_color=003d4c&text_color=ffffff&primary_color=00eae9"
                        pageUrl="the-wave-machine-digital-product-clinic"
                    />
                </SwellWrapper>

                <Constrain type="narrow">
                    <div className="additional-questions">
                        <HeadingLevel3>Additional questions</HeadingLevel3>
                        <h4>Can’t get to us?</h4>
                        <p>
                            No problem! We’re fully geared up for remote working
                            and that extends to our clinic. Just let us know
                            when you book an appointment.
                        </p>
                        <h4>Worried about confidentiality?</h4>
                        <p>
                            We can have an NDA signed in two shakes of a lamb's
                            tail.
                        </p>
                        <h4>What’s in it for us?</h4>
                        <p>
                            We want to see good ideas succeed and are confident
                            that we can help to increase your chances of
                            success. You never know, when you’re the next
                            Unicorn, you may remember us and throw a big party
                            in our honour.
                        </p>
                    </div>
                </Constrain>
            </DigitalProductClinicPageContent>
            <CallToContact />
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

export default withLayout(DigitalProductClinic);
